import Head from "next/head";

type PageHeadProps = {
  primaryText?: string;
  secondaryText?: string;
  tertiaryText?: string;
};

const optionalText = (text?: string) => (text ? `${text} - ` : "");

/**
 * Sets the title in the browser tab
 */
export const PageHead: React.FC<PageHeadProps> = ({
  primaryText,
  secondaryText,
  tertiaryText,
}) => (
  <Head>
    <title>
      {`${
        optionalText(primaryText || "") +
        optionalText(secondaryText || "") +
        optionalText(tertiaryText || "")
      }Caltana`}
    </title>
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    <meta charSet="utf-8" />
  </Head>
);
