// components/Header.js
import Link from "next/link";
import Image from "next/legacy/image";
import server from "../config/server";

const Header = () => (
  <div className="Header">
    <Link href="/auth/login">
      <Image
        src={`${server.SITE_URL}logo-lg.svg`}
        alt="Caltana Logo"
        width={125}
        height={21}
      />
    </Link>
  </div>
);

export default Header;
