import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Image from "next/legacy/image";
import server from "config/server";
import { PageHead } from "./PageHead";
import Header from "./Header";

const useStyles = makeStyles({
  wrapper: {
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const Layout = ({ title, titleComponent, children }) => {
  const appTitle = `Caltana`;
  const classes = useStyles();
  return (
    <div className="layout">
      <PageHead />
      <Header appTitle={appTitle} />
      <div className="content">
        <Container component="main" maxWidth="sm">
          <Box className={classes.wrapper}>
            <Card variant="outlined">
              <CardContent>
                <Box style={{ textAlign: "center" }}>
                  <Image
                    alt="Logo"
                    src={`${server.SITE_URL}logo.svg`}
                    height={40}
                    width={40}
                    className={classes.logoWrapper}
                  />
                </Box>
                <Typography
                  component={titleComponent}
                  variant="h5"
                  align="center"
                >
                  {title}
                </Typography>
                {children}
              </CardContent>
            </Card>
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default Layout;
